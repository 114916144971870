import React from "react"
import Layout from "../components/layout"
import { graphql, Link, PageProps } from "gatsby"
import SEO from "../components/seo"
import { FluidObject } from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
// @ts-ignore
import Fade from "react-reveal/Fade"
import TeamSection from "../components/TeamSection/TeamSection"
// @ts-ignore
import { getSectionsData} from "../util/helpers"

interface IAboutProps extends PageProps<{
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}> {
  className?: string
}

const About_us: React.FC<IAboutProps> = ({ className, location:{pathname:path}, data, pageContext }) => {
  const metaText = data.template.locales.find(l => l.iso === pageContext.lang)
  const secData = getSectionsData(data.template.sections)
  // const teamSectionData = { team: getSectionsData(data.template.sections)}
  // @ts-ignore
  return (
    <Layout lang={pageContext.lang} page={path}>
      <SEO
        title={metaText?.metaTitle || "We provide our partners with quality design resources in creating beautiful functional spaces."}
        description={metaText?.metaDescription || "Casa Italia partners with Italian fine furniture manufacturers to provide beautiful and functional residential, corporate, and hotel spaces."}
        keywords="Italian furniture, Luxury furniture, Fine furniture, Design solutions, Contemporary design"
      />
      <div className={className}>
        <div className="content content--about content--inner">
          <Fade top>
            <PageTitle>
              Imagine <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Explore <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Define
            </PageTitle>
          </Fade>
          <section className="section about-page">
            <div className="about-content">
              <p><span>CASA ITALIA</span> is a uniquely flexible luxury design resource which has partnered with three
                extraordinary Italian fine furniture
                manufacturers - <Link className="red-text" to="/brands/fimes">Fimes</Link>, <Link
                  className="red-text" to="/brands/mussi">Mussi</Link> and <Link className="red-text"
                                                                                 to="/brands/mk">MK
                  Kitchens</Link> - to offer the highest quality design solutions to architects, designers and developers.
                <br />
                <br />
                Quality design balances shapes, textures, colors and periods in order to create a balance of the
                client’s personality and designer’s vision.
                We support this process by providing our partners with a deluge of the highest quality design
                resources at the best prices
                in order to aid their efforts in creating beautiful functional spaces.</p>
              <GatsbyImage alt="sofa" image={getImage(data.file.childImageSharp)} />
              <p style={{marginTop: "-15%"}}>Our broad range of contacts in the global furnishings market, along with the
                arts and design communities,
                ensures that our partners always have access to the very best selection of contemporary design options
                on the market and enables us to provide complete and scalable high end solutions for all your projects -
                from a single luxury residential project
                or corporate/commercial environment to multi-unit hotel/condominium developments.
                <br />
                <br />
                All of us at <span>CASA ITALIA</span>, along with our trusted partners, are committed to excellence
                at every stage of the process in an effort to create and nurture life long relationships with our
                clients.
                Work with <span>CASA ITALIA</span> and experience what it feels like to be truly cared for in your
                design process.</p>
            </div>
          </section>
          <div style={{margin:"5% 0", background:"#f1f1f1"}}>
            <TeamSection sectionData={secData.team} lang={pageContext.lang} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
{
   file(relativePath: {eq: "ci_fotelja_aboutus.png"}) {
    childImageSharp {
        gatsbyImageData(quality: 90)
    }
  }
  template(name: {eq: "about_us"}) {
      locales {
        metaDescription
        metaKeywords
        metaTitle
        iso
        value
      }
      sections {
        keyPrefix
        items {
          key
          ... on DataItemTextGet {
            __typename
            locales {
              iso
              value
            }
          }
        }
        ... on SectionModuleGet {
          __typename
          selectedModules {
            ... on MemberGet {
              uid
              type
              imageFile {
                  publicURL
                  childImageSharp {
                      gatsbyImageData( layout: CONSTRAINED, transformOptions: {cropFocus: CENTER}, quality: 90)
                }
              }
              locales {
                title
                iso
                description
              }
              additionalData {
                locales {
                  iso
                  value
                }
              }
            }
          }
        }
      }
    }
 }
`

export default styled(About_us)`
  color:black;
  .about-page {
    max-width:1200px;
    margin:auto;
    padding:30px 0 60px 0;
  }
  .sofa {
    width:100%;

    @media screen and (min-width:768px) {
      width:80%
    }
    display:block;
    margin:10% auto 5% auto;
  }
  .about-content {
    font-family: "California FB Regular",serif;
    width:100%;
    padding:10px;
    background-color:#e9e9e9;
    @media screen and (min-width:768px) {
      width:85%;
      padding:30px;
    }
    margin:auto;
    line-height: 26px;
    .red-text {
      font-family: BankGothicLtBTLight;
      text-transform: uppercase;
    }
    span{
      font-family: BankGothicLtBTLight;
    }
  }
`
