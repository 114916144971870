import React from "react"
import styled from "styled-components"
import { SectionTitle, SpanDivider } from "../UtilComponents"
import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// @ts-ignore
// import ReactFancyBox from "../ReactFancyBox/ReactFancyBox"
import 'react-fancybox/lib/fancybox.css'
import { graphql, useStaticQuery } from "gatsby"
// @ts-ignore
import Fade from 'react-reveal/Fade';

interface ITeamSectionProps {
  className?: string
  sectionData?: {}
  lang: string
}

const TeamSection: React.FC<ITeamSectionProps> = ({ className, sectionData, lang }) => {
  // console.log("Team data: ", sectionData)
  const data = useStaticQuery(
    graphql`
      query {
        branka: file(relativePath: { eq: "team/ci_branka.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         filip: file(relativePath: { eq: "team/ci_filip.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         goran: file(relativePath: { eq: "team/ci_goran.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageBranka = data.branka.childImageSharp.fluid
  const imageFilip = data.filip.childImageSharp.fluid
  const imageGoran = data.goran.childImageSharp.fluid
  return (
    <section className={className}>
      <SectionTitle>
        Meet the <SpanDivider> | </SpanDivider> team
      </SectionTitle>
      <p className="section__subinfo">{sectionData.description[lang]}</p>
      <div className="team__boxes">
        {sectionData.selectedModules.length > 0 && sectionData.selectedModules.map((sm) => {
            const img = getImage(sm.imageFile)
            return <Fade key={sm.uid} bottom>
              <div className="team__box">
                <div className="member-pic">
                  {/*<ReactFancyBox*/}
                  {/*  gatsby={true}*/}
                  {/*  fluid={imageBranka}/>*/}
                  <a data-fancybox="gallery" href={sm.imageFile.publicURL}>
                    {/*<Img fluid={sm.imageFile.childImageSharp.fluid} />*/}
                    <GatsbyImage alt={sm.memberName || ""} image={img} />
                  </a>
                </div>
                <div className="member-info">
                  <div className="holder">
                    <span className="name">{sm.memberName} {sm.memberSurname}</span>
                    <span className="role">{sm.locales.find(l => l.iso === lang).description}</span>
                    <span className="personal-quote">{sm.additionalData[0].locales.find(l => l.iso === lang).value}</span>
                  </div>
                </div>
              </div>
            </Fade>
        })}
        {/*<Fade bottom>*/}
        {/*  <div className="team__box">*/}
        {/*    <div className="member-pic">*/}
        {/*      /!*<ReactFancyBox*!/*/}
        {/*      /!*  gatsby={true}*!/*/}
        {/*      /!*  fluid={imageBranka}/>*!/*/}
        {/*      <a data-fancybox="gallery" href={data.branka.publicURL}>*/}
        {/*        <Img fluid={imageBranka} />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*    <div className="member-info">*/}
        {/*      <div className="holder">*/}
        {/*        <span className="name">Branka Kojic</span>*/}
        {/*        <span className="role">USA Managing Partner</span>*/}
        {/*        <span className="personal-quote">Los Angeles</span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Fade>*/}
        {/*<Fade bottom>*/}
        {/*  <div className="team__box">*/}
        {/*    <div className="member-pic">*/}
        {/*      /!*<ReactFancyBox*!/*/}
        {/*      /!*  gatsby={true}*!/*/}
        {/*      /!*  fluid={imageBranka}/>*!/*/}
        {/*      <a data-fancybox="gallery" href={data.branka.publicURL}>*/}
        {/*        <Img fluid={imageBranka} />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*    <div className="member-info">*/}
        {/*      <div className="holder">*/}
        {/*        <span className="name">Branka Kojic</span>*/}
        {/*        <span className="role">USA Managing Partner</span>*/}
        {/*        <span className="personal-quote">Los Angeles</span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Fade>*/}
        {/*<Fade bottom delay={300}>*/}
        {/*  <div className="team__box">*/}
        {/*    <div className="member-pic">*/}
        {/*      /!*<ReactFancyBox*!/*/}
        {/*      /!*  gatsby={true}*!/*/}
        {/*      /!*  fluid={imageFilip}/>*!/*/}
        {/*      <a data-fancybox="gallery" href={data.filip.publicURL}>*/}
        {/*        <Img fluid={imageFilip} />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*    <div className="member-info">*/}
        {/*      <div className="holder">*/}
        {/*        <span className="name">Filip Kojic</span>*/}
        {/*        <span className="role">Account Director</span>*/}
        {/*        <span className="personal-quote">Los Angeles</span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Fade>*/}
        {/*<Fade bottom delay={600}>*/}
        {/*  <div className="team__box">*/}
        {/*    <div className="member-pic">*/}
        {/*      /!*<ReactFancyBox*!/*/}
        {/*      /!*  gatsby={true}*!/*/}
        {/*      /!*  fluid={imageGoran}/>*!/*/}
        {/*      <a data-fancybox="gallery" href={data.goran.publicURL}>*/}
        {/*        <Img fluid={imageGoran} />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*    <div className="member-info">*/}
        {/*      <div className="holder">*/}
        {/*        <span className="name">Goran Popovic</span>*/}
        {/*        <span className="role">Managing Partner Italy</span>*/}
        {/*        <span className="personal-quote">Milan</span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Fade>*/}
      </div>
    </section>
  )
}

export default styled(TeamSection)`
  background: ${props => props.background ? props.background : "#f1f1f1"};
  padding:50px 5% 60px;
  color:black;
  .section__subinfo {
    font-family: "California FB Regular";
    text-align: center;
  }
  .team__boxes {
    margin-top: 50px;
    width:100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width:900px) {
      flex-wrap:nowrap;
    }
  }
  .team__box {
    width:100%;
    display:flex;
    flex-wrap: wrap;
    margin-bottom:20px;

    @media screen and (min-width:700px) and (max-width:900px) {
      flex-wrap:nowrap;
      &:nth-child(2) {
        flex-direction: row-reverse;
      }
    }
    @media screen and (min-width:900px) {
      // width:susy-span(4);
      // margin-right:15px;
      &:nth-child(2) {
        flex-direction: row;
      }
      margin-right:2%;
      &:last-child {
        margin-right:0;
      }
    }
  }
  .member-pic {
    width:100%;
    img {
      display:block;
      opacity:1 !important;
    }
    //@media screen and (min-width:700px) and (max-width:900px) {
    //  width:65%;
    //}
    &:hover {
      cursor: pointer;
    }
  }
  .member-info {
    padding:35px 10px;
    background:#ffffff;
    width:100%;
    display:flex;
    align-items: center;
    color:black;
    text-align: center;
    .holder {
      width:100%;
    }
    span {
      display:block;
      &.name {
        font-family: 'Bankgothic Regular';
        font-weight:bold;
        color: red;
        font-size:1.2em;
        margin-bottom:25px;
      }
      &.role {
        text-transform: uppercase;
        margin-bottom:10px;
        font-family: "California FB Regular";
        letter-spacing: 1.3px;
      }
      &.personal-qoute {
        &:after, &:before {
          content: '"'
        }
      }
    }
    @media screen and (min-width:700px) and (max-width:900px) {
      // width:susy-span(4);
      // float:left
      width:35%;
    }
  }
  .react-fancybox {
    .box {
      z-index:200;
    }
    .image-box {
      width:85%;
    }
  }
`
